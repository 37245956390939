<div class="row" *ngIf="this.detalle !== undefined && this.detalle !== null" >
  <app-form-text-edit  class="col-xl-3" i18n-label label="Lote"
      [(value)]="this.detalle.lote"  [isReadOnly]="true" ></app-form-text-edit>
    <app-form-date-edit class="col-sm-3" i18n-label label="Fecha creación" [(value)]="this.detalle.fechaCreacion" [disabled]="true" type="calendar"></app-form-date-edit>
    <app-form-text-edit  class="col-xl-3" i18n-label label="Tipo"
      [(value)]="this.detalle.tipo"  [isReadOnly]="true" ></app-form-text-edit>
    <app-form-date-edit class="col-sm-3" i18n-label label="Fecha edicto" [(value)]="this.detalle.fechaEdicto" [disabled]="true" type="calendar"></app-form-date-edit>
  </div>
<div class="row justify-content-between">
  <!-- <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div> -->
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

    <ng-container matColumnDef="expediente">
      <mat-header-cell *matHeaderCellDef>Expdiente</mat-header-cell>                  
      <mat-cell class="cCell" *matCellDef="let element"> {{element.expediente}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>                  
      <mat-cell class="cCell" *matCellDef="let element"> {{element.nombre }} </mat-cell>
    </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
