import { GalleryComponent } from 'path-shared/components/gallery/gallery/gallery.component';
import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, EventEmitter, Output, SimpleChanges, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { DeteccioMatricula } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { DeteccioMatriculaFilter } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula-filter';
import { DeteccioMatriculesService } from 'app/sections/ciutat/deteccio-matricules/services/deteccio-matricules.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DeteccioMatriculesFilterService } from 'app/sections/ciutat/deteccio-matricules/services/deteccio-matricules-filter.service';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Builder } from 'path-shared/models/builder';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { SingleEditDialogData } from 'path-shared/components/dialogs/single-edit-dialog/single-edit-dialog-data.model';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { DisplayTextDialogComponent } from 'path-shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { PhotoBoxGalleryComponent } from 'path-shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
import { ImagesService } from 'path-shared/services/images/images.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { DomSanitizer } from '@angular/platform-browser';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';

@Component({
  selector: 'app-deteccio-matricules-table',
  templateUrl: './deteccio-matricules-table.component.html',
  styleUrls: ['./deteccio-matricules-table.component.scss']
})
export class DeteccioMatriculesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('photoBoxGallery') photoBoxGallery: PhotoBoxGalleryComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: ElementRef;

  public data: Subject<DeteccioMatricula[]>;
  dataSource: CustomDataSource<DeteccioMatricula, DeteccioMatriculaFilter>;
  highlightedRows = [];
  filter: DeteccioMatriculaFilter = new DeteccioMatriculaFilter('data', 'desc');
  filterPage: DeteccioMatriculaFilter;
  displayedItems = [];
  allSelected = false;

  @Input() updatedFilterData: DeteccioMatriculaFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'matricula', 'data', 'llista', 'agent', /*'PDA',*/ 'aparell', 'zona', 'comprovacio', 'tiquet', 'error', 'resposta', 'imatges', 'dgt', 'idDenuncia'];

  constructor(
    private deteccioMatriculesService: DeteccioMatriculesService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private imagesService: ImagesService,
    private dialog: MatDialog,
    private i18n: I18n,
    private sanitizer: DomSanitizer
    ) {
        this.data = new Subject<DeteccioMatricula[]>();
        this.selectButton = true;
    }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<DeteccioMatricula, DeteccioMatriculaFilter>(this.paginator, new DeteccioMatriculesFilterService(), 'urlGetDeteccioMatricules', 'urlDeteccioMatriculesPaginationCount');
    this.dataSource.subject.subscribe((data) => {

      let i = 0;
      data.forEach(item => {
        item.markerClicked = false;
        this.deteccioMatriculesService.displayedItems[i] = {
          id: item.id,
          checked: false,
          latitud: item.latitud,
          longitud: item.longitud
        };
        i++;
      });
       
      this.displayedItems = this.deteccioMatriculesService.displayedItems

      this.data.next(data);
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('deteccio-matricules-filter', this.filter); }
      }
    });
    /* if (!this.selectButton) { this.filterService.loadFilter('deteccio-matricules', this.filter); } */
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
      this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  markerClick(row) {


    const scrollPos = ((this.table.nativeElement.scrollHeight * this.displayedItems.findIndex(x => x.id === row.id)) / this.displayedItems.length);

    this.table.nativeElement.scrollTop = scrollPos - 5;
    this.dataSource.getData().forEach(r => {
      r.markerClicked = false;
    });
    this.dataSource.getData().find(x => x.id === row.id).markerClicked = true;
  }

  onRowClicked(row) {

    this.deteccioMatriculesService.displayedItems.find(x => x.id === row.id).checked = !this.deteccioMatriculesService.displayedItems.find(x => x.id === row.id).checked;
    if (this.deteccioMatriculesService.displayedItems.find(x => x.id === row.id).checked === false) {
      this.dataSource.getData().find(x => x.id === row.id).markerClicked = false;
    }
    this.itemClicked.next(row.secure_id);
  }

  clickCheckbox(row) {
    this.deteccioMatriculesService.displayedItems.find(x => x.id === row.id).checked = !this.deteccioMatriculesService.displayedItems.find(x => x.id === row.id).checked;
    if (this.deteccioMatriculesService.displayedItems.find(x => x.id === row.id).checked === false) {
      this.dataSource.getData().find(x => x.id === row.id).markerClicked = false;
    }

  }
  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  SeleccionarTotes() {
    this.deteccioMatriculesService.displayedItems.forEach(row => {
      if (this.allSelected === true) {
        row.checked = true;
      } else {
        row.checked = false;
      }
    });
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.deteccioMatriculesService, filter, this.i18n('DeteccioMatricules'), this.sort.direction, this.sort.active)
    });
  }

  getImageClass(images) {
    if (Reflection.empty(images)) {
      return 'fa fa-ban';
    } else {
      return 'fa fa-images';
    }
  }

  showResposta(deteccio: DeteccioMatricula) {
    this.dialog.open(DisplayTextDialogComponent, {
      closeOnNavigation: false,
      width: '50%',
      autoFocus: true,
      data: {text: deteccio.resposta, type: 'json'}
    });

  }

  showTiquet(tiquet: string) {
    this.dialog.open(DisplayTextDialogComponent, {
      closeOnNavigation: false,
      width: '50%',
      autoFocus: true,
      data: {text: tiquet, type: 'html'}
    });

  }

  showDenuncia(idDenuncia) {
    const denunciaDialog = this.dialog.open(DenunciesEditorComponent,
    {
      closeOnNavigation: false,
      autoFocus: true,
      data: {'id' : idDenuncia},
      width: '90%',
    });
  }

  loadImage(image: string) {
    return `${config.urls['apiUrl'].replace('{hostname}', window.location.hostname)}${api.endpoints['urlGetImageByPath']}?path=${image}`;
  }
  showImages(deteccio: DeteccioMatricula) {
    if (!Reflection.empty(deteccio.imatges)) {
      const images: any[] = [];
      for (let i = 0; i < deteccio.imatges.split(',').length; i++) {
        let image = deteccio.imatges.split(',')[i]
        //image = this.replaceAll(image, '/\\\\/', '\\');

        images.push(this.loadImage(image))
        // images.push(this.deteccioMatriculesService.getImageUrl(deteccio.secure_id, i, 'L'));
      }

      const data: ComponentDialog = Builder.newComponentDialog(true, false, deteccio.matricula);
      data.tag = images;
      data.title = this.i18n('Matrícula') + ' ' + deteccio.matricula;

      //this.photoBoxGallery.loadImages(images);
      this.dialog.open(GalleryComponent, {
        closeOnNavigation: false,
        // width: '99%',
        // height: '99%',
        autoFocus: true,
        data: data
      });
    }
  }
  
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  hasImages(images): boolean {
    return !Reflection.empty(images);
  }

  rowIndex(row) {
    return this.displayedItems.findIndex(x => x.id === row.id);
  }
  showDGT(row: DeteccioMatricula) {
    if ( row.matricula !== undefined && row.matricula !== null && row.matricula !== '') {
      const ref = this.dialog.open(XmlTreeViewerComponent, {
        width: '50%',
        height: '75%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': true,
          'matricula': row.matricula,
          'persona': '',
          'propietario': '',
          'incorporaData': false,
          'consulta': false,
          'secureIdDenuncia': '',
          'loadDataOnLoad': true

        }
      });
    }
  }
}
